// DatePicker


function initDatePicker() {
    // general
    var date = $('.datepicker');
    var from_date = $('[name="from_date"], [name="from"]')
    var to_date = $('[name="to_date"], [name="to"]')
    var options = {
        labelMonthNext: 'Go to the next month',
        labelMonthPrev: 'Go to the previous month',
        labelMonthSelect: 'Pick a month from the dropdown',
        labelYearSelect: 'Pick a year from the dropdown',
        selectMonths: true,
        selectYears: true,
        hiddenName: null,
        monthsFull: general.labels[general.lang]["monthsFull"],
        monthsShort: general.labels[general.lang]["monthsShort"],
        weekdaysFull: general.labels[general.lang]["weekdaysFull"],
        weekdaysShort: general.labels[general.lang]["weekdaysShort"],
        today: general.labels[general.lang]["today"],
        clear: general.labels[general.lang]["clear"],
        format: 'yyyy-mm-dd',
        formatSubmit: 'yyyy-mm-dd',
        min: new Date(1900, 3, 20),
        max: true
    }
    date.each(function (index, item) {
        item = $(item)
        if (item.hasClass('date-startToday')) {
            options.min = new Date()
            options.max = false
        }
        item.pickadate(options);
    })
    // to date
    to_date.pickadate(options)
    // from date
    var fromDateOption = options;
    fromDateOption.onSet = function (ev) {
        to_date.pickadate('picker').set('select', new Date(ev.select))
        to_date.pickadate('picker').get('select')
    }
    from_date.pickadate(fromDateOption)
}
var countryValue;
function updateDoctorType() {
    var country = $('[name="country"]');
    var type = $('[name="speciality"], [name="area"]');

    country.on('change', function (ev) {
        countryValue = country.val();

        switch (countryValue) {
            case 'Egypt':
                type.find('[type="Egypt"]').show()
                type.find('[type="Yemen"]').hide()
                break;
            case 'Yemen':
                type.find('[type="Yemen"]').show()
                type.find('[type="Egypt"]').hide()
                break;
        }
    })
}
// validation

// $.validator.addMethod('customphone', function (value, element) {
//     return this.optional(element) || /^\+[1-9]{1}[0-9]{3,14}$/.test(value);
// }, "الرجاء ادخال رقم الهاتف");

/*
$(".left_form , .basicProfile_form").each(function () {
    $('.left_form , .basicProfile_form ').validate({
        rules: {
            field: {
                required: true
            },
            phone: {
                equalTo: "#phone",
                required: true,
                pattern: "^[0-9+]*$"
            },
            email_confirm: {
                equalTo: "#email_input"
            },
            agree: {
                required: true
            }
        },
        messages: {
            name: general.labels["name"],
            email: general.labels["email"],
            phone: general.labels["phone"],
            phone_pattern: general.labels["phone_pattern"],
            phone_number_contact: general.labels["phone"],
            area: general.labels["area"],
            speciality: general.labels["speciality"],
            years: general.labels["years"],
            password: general.labels["password"],
            agree: general.labels["agree"],
            full_name: general.labels["full_name"],
            phone_number: general.labels["phone_number"],
            message: general.labels["message"],
            OldPassword: general.labels["OldPassword"],
            dob: general.labels["dob"],
            city_id: general.labels["city_id"],
            area_id: general.labels["area_id"],
            address: general.labels["address"],
            last_name: general.labels["last_name"],
            first_name: general.labels["first_name"],
            middle_name: general.labels["middle_name"],
            gender: general.labels["gender"],
            country_id: general.labels["country_id"],
            syndicate_number: general.labels["syndicate_number"],
            permit_number: general.labels["permit_number"],
            postImage: general.labels["postImage"],
            email_confirm: general.labels["email_confirm"],
            permit_register_date: general.labels["permit_register_date"],
            permit_release_date: general.labels["permit_release_date"],
            permit_end_date: general.labels["permit_end_date"],
            licenses_number: general.labels["licenses_number"],
            work_hours_from: general.labels["work_hours_from"],
            working_hours_to: general.labels["working_hours_to"],
            type_of_services: general.labels["type_of_services"],
            phone_number_whatsapp: 'الرجاء إدخال رقم الواتس اب',
            reserve_date: 'الرجاء إدخال اليوم',
            reserve_time: 'الرجاء إدخال التوقيت',
            checkbox: "يجب عليك الموافقة علي الشروط",
        }
    })
});
*/

$('.profile_password').validate({

    rules: {

        password_confirm: {
            equalTo: "#password"
        },

        field: {
            required: true
        },
        agree: {
            required: true
        }
    },
    messages: {
        password: general.labels["password"],
        OldPassword: general.labels["OldPassword"],
        password_confirm: general.labels["password_confirm"],
    }
});
$('#qualificationForm').validate({
    rules: {
        field: {
            required: true
        },
        agree: {
            required: true
        }
    },
    messages: {
        name: general.labels["speciality_name"],
        type: general.labels["speciality_type"],
        issued_date: general.labels["speciality_issued_date"],
        institute: general.labels["speciality_institute"],
        occupation: general.labels["speciality_occupation"],
        ref_number: general.labels["speciality_ref_number"],
    }
});
$('#experienceForm').validate({
    rules: {
        field: {
            required: true
        },
        agree: {
            required: true
        }
    },
    messages: {
        experience_title: general.labels["experience_title"],
        experience_start_date: general.labels["experience_start_date"],
        experience_until_date: general.labels["experience_until_date"],
        experience_place: general.labels["experience_place"],
        experience_description: general.labels["experience_description"],
    }
});
$('#specializedForm').validate({
    rules: {
        field: {
            required: true
        },
        agree: {
            required: true
        }
    },
    messages: {
        specialityName: general.labels['label-speciality_name']
    }
});
// UPLOAD IMG
$("input.upload").change(function (e) {
    for (var i = 0; i < e.originalEvent.srcElement.files.length; i++) {
        var file = e.originalEvent.srcElement.files[i];
        var img = document.createElement("img");
        var reader = new FileReader();
        reader.onloadend = function () {
            img.src = reader.result;
        }
        reader.readAsDataURL(file);
        $("input.upload").after(img);
    }
});


// Retrieve


$(document).ready(function () {

    updateDoctorType()

    var owl = $('.intro .owl-carousel');

    owl.owlCarousel({
        loop: true,
        items: 1,
        autoplay: true,
        autoPlaySpeed: 4000,
        autoPlayTimeout: 4000,
        autoplayHoverPause: true,
        dots: true,
        responsiveClass: true,
        rtl: true,
        nav: true,
        lazyLoad: true,
        navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    })

    $('.intro-slider-nav-next').click(function () {
        owl.trigger('next.owl.carousel');
    })
    $('.intro-slider-nav-prev').click(function () {
        owl.trigger('prev.owl.carousel');
    })
    $('.intro-slider-nav-next').on('mouseenter', function () {

        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_right-24px-pink.svg')

    })
    $('.intro-slider-nav-next').on('mouseleave', function () {

        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_right-24px.svg')

    })

    $('.intro-slider-nav-prev').on('mouseenter', function () {
        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_left-24px-pink.svg')
    })
    $('.intro-slider-nav-prev').on('mouseleave', function () {
        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_left-24px.svg')
    })


    var owl_doctors = $('.ourdoctor-main.landing-doctors .our-doctors .owl-carousel');
    owl_doctors.owlCarousel({
        loop: true,
        items: 1,
        autoplay: true,
        autoPlaySpeed: 4000,
        autoPlayTimeout: 4000,
        autoplayHoverPause: true,
        dots: true,
        responsiveClass: true,
        rtl: true,
        nav: true,
        lazyLoad: true,
        navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    })

    var owl_ourdoctor = $('.ourdoctor-main .our-doctors-new .owl-carousel');
    var count_owl_ourdoctor = $('.ourdoctor-main .our-doctors-new .owl-carousel .card').length;

    owl_ourdoctor.owlCarousel({
        items: 3,
        autoplay: true,
        autoPlaySpeed: 4000,
        autoPlayTimeout: 4000,
        autoplayHoverPause: true,
        dots: true,
        responsiveClass: true,
        margin: 30,
        rtl: true,
        nav: true,
        navText: ['<i class="fa fa-long-arrow-left" aria-hidden="true"></i>', '<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'],


        responsive: {
            0: {
                items: 1,
                nav: false,
                maring: 0
            },
            480: {
                items: 1,
                nav: false,
                maring: 0
            },
            600: {
                items: 2,
                nav: false,
                maring: 20
            },
            1000: {
                items: 4,
                nav: count_owl_ourdoctor > 4 ? true : false,
                maring: 20,
                dots: true,
            }
        },
    })
    $('.our-doctors-slider-nav-next').click(function () {
        owl_ourdoctor.trigger('next.owl.carousel');
    })
    $('.our-doctors-slider-nav-prev').click(function () {
        owl_ourdoctor.trigger('prev.owl.carousel');
    })
    $('.our-doctors-slider-nav-next').on('mouseenter', function () {

        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_right-24px-pink.svg')

    })
    $('.our-doctors-slider-nav-next').on('mouseleave', function () {

        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_right-24px.svg')

    })

    $('.our-doctors-slider-nav-prev').on('mouseenter', function () {
        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_left-24px-pink.svg')
    })
    $('.our-doctors-slider-nav-prev').on('mouseleave', function () {
        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_left-24px.svg')
    })


    var owl_ourdoctor = $('.latest-midical-news .our-doctors-new .owl-carousel');
    var count_owl_ourdoctor = $('.latest-midical-news .our-doctors-new .owl-carousel .card').length;

    owl_ourdoctor.owlCarousel({
        items: 3,
        autoplay: true,
        autoPlaySpeed: 4000,
        autoPlayTimeout: 4000,
        autoplayHoverPause: true,
        dots: true,
        responsiveClass: true,
        margin: 30,
        rtl: true,
        nav: true,
        navText: ['<i class="fa fa-long-arrow-left" aria-hidden="true"></i>', '<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'],


        responsive: {
            0: {
                items: 1,
                nav: false,
                maring: 0
            },
            480: {
                items: 1,
                nav: false,
                maring: 0
            },
            600: {
                items: 2,
                nav: false,
                maring: 20
            },
            1000: {
                items: 3,
                nav: count_owl_ourdoctor > 3 ? true : false,
                maring: 20,
                dots: true,
            }
        },
    })


    var owl_ourdoctor = $('#yemen-landing-doctor .owl-carousel');

    owl_ourdoctor.owlCarousel({
        items: 3,
        autoplay: true,
        autoPlaySpeed: 4000,
        autoPlayTimeout: 4000,
        autoplayHoverPause: true,
        dots: true,
        responsiveClass: true,
        margin: 30,
        rtl: true,
        nav: true,
        navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],


        responsive: {
            0: {
                items: 1,
                nav: false,
                maring: 0
            },
            480: {
                items: 1,
                nav: false,
                maring: 0
            },
            600: {
                items: 2,
                nav: false,
                maring: 20
            },
            1000: {
                items: 3,
                nav: false,
                maring: 20,
                dots: false,
            }
        },
    })


    // var owl_ourdoctor = $('.our-doctors .owl-carousel');
    var owl_blog_tags = $('#blogs-section .tags.owl-carousel');

    owl_blog_tags.owlCarousel({
        items: 20,
        autoplay: false,
        autoPlaySpeed: 4000,
        autoPlayTimeout: 4000,
        autoplayHoverPause: true,
        dots: false,
        responsiveClass: true,
        margin: 30,
        rtl: true,
        nav: true,
        navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],


        responsive: {
            0: {
                items: 2,
                margin: 80,
            },

            1000: {
                items: 10,
            }
        },
    })


    var owl_testimonial = $('.testimonial-content .owl-carousel');

    owl_testimonial.owlCarousel({
        loop: true,
        items: 1,
        autoplay: true,
        autoPlaySpeed: 4000,
        autoPlayTimeout: 4000,
        autoplayHoverPause: true,
        dots: true,
        responsiveClass: true,
        rtl: true,
        nav: true,
        navText: ['<i class="fa fa-long-arrow-left" aria-hidden="true"></i>', '<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'],

        responsive: {
            0: {
                items: 1,
                nav: true,
                dots: true,
            },
            1000: {
                items: 1,
                nav: true,
            }
        }

    })
    $('.testimonial-content-slider-nav-next').click(function () {
        owl_testimonial.trigger('next.owl.carousel');
    })
    $('.testimonial-content-slider-nav-prev').click(function () {
        owl_testimonial.trigger('prev.owl.carousel');
    })
    $('.testimonial-content-slider-nav-next').on('mouseenter', function () {

        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_right-24px-pink.svg')

    })
    $('.testimonial-content-slider-nav-next').on('mouseleave', function () {

        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_right-24px.svg')

    })

    $('.testimonial-content-slider-nav-prev').on('mouseenter', function () {
        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_left-24px-pink.svg')
    })
    $('.testimonial-content-slider-nav-prev').on('mouseleave', function () {
        $(this).find('img').attr('src', general.BASEURL + '/assets/images/baseline-keyboard_arrow_left-24px.svg')
    })

    $('.password_forms .save').click(function (e) {
        if ($("#password").val() == $('#oldPassword').val()) {
            $('#oldPassword').addClass('error');
            if ($('#oldpassword_error').length == 0) {
                $('#oldPassword').after('<label id="oldpassword_error" class="error" for="password_confirmation">' + general.labels['label_password_validation'] + '</label>');
            }
            e.preventDefault();
        }
    });


    $('.printPayment').click(function (e) {
        window.print();
        e.preventDefault();

    });
    $("a.grouped_elements").fancybox();

    $('.notification').on('click', function (e) {
        $(this).toggleClass('show_notification');
        return false;
        e.preventDefault()
    })



    $('.changePassword').on('click', function (e) {
        $(this).remove()
        $('.password_forms').toggleClass('showForm');
        e.preventDefault();
        return false;

    })

    $('.clinic-details-tabs ul.tabs li').click(function () {
        var tab_id = $(this).attr('data-tab');

        $('.clinic-details-tabs ul.tabs li').removeClass('current');
        $('.clinic-details-tabs .tab-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    })


    $('.profile_password .top_profile_details , .basic_details').addClass('showDetails');


    $('.how_it_works_page .mobile_container').animate({
        'opacity': '1'
    }, 3000);
    var ravenous = function () {
        if (window.matchMedia('(max-width: 1024px)').matches) {
            $('.header_profile_container').click(function (e) {
                e.preventDefault();
                $('.logedin_header_link.link_back , .logout_header_link ').toggleClass('show');
            });
        }
    };
    $(window).resize(ravenous);
    ravenous();

    $('#certificates_profile .toggleSection').on('click', function () {

        $(this).toggleClass('down_arrow')
        $(this).find('i').toggleClass('fa-chevron-down fa fa-chevron-up');
        $('body div').not('#certificates_profile  .certificates_details').removeClass('showDetails');
        $('#certificates_profile  .certificates_details').toggleClass('showDetails');

    })

    $('#experience_profile .toggleSection').on('click', function () {

        $(this).toggleClass('down_arrow')
        $(this).find('i').toggleClass('fa-chevron-down fa fa-chevron-up');
        $('body div').not('#experience_profile .experience_details').removeClass('showDetails');
        $('#experience_profile .experience_details').toggleClass('showDetails');

    })

    $('#specializedIn_profile .toggleSection').on('click', function () {

        $(this).toggleClass('down_arrow')
        $(this).find('i').toggleClass('fa-chevron-down fa fa-chevron-up');

        $('body div').not('.specialized_details').removeClass('showDetails');
        $('#specializedIn_profile .specialized_details').toggleClass('showDetails');
    })


    $('.basic .toogleSection').on('click', function (e) {

        $(this).toggleClass('down_arrow')
        $(this).find('i').toggleClass('fa-chevron-up fa fa-chevron-down');
        $('body div').removeClass('showDetails');
        $('.basic_details').toggleClass('hideDetails');


    })

    $('.password_forms .toogleSection').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('down_arrow')
        $(this).find('i').toggleClass('fa-chevron-up fa fa-chevron-down');
        $('body div').removeClass('showDetails');
        $('.password_forms .top_profile_details').toggleClass('hideDetails');


    })


    $('.profile_notifiacation span').on('click', function (e) {
        e.preventDefault();
        $(this).parent().addClass('removeDiv');


    })


    if (document.getElementById('model-homepage-confirmation') !== null && typeof document.getElementById('model-homepage-confirmation') !== 'undefined') {
        // handle confirm message submit form homepage

        var modal_confirm_homepage = document.querySelector(".modal-confirm-homepage");
        var btn_confirm_homepage = document.querySelector(".openModal");
        var span_confirm_homepage = document.querySelectorAll(".close-confirm-homepage");
        var submit_btn_confirm_homepage = document.querySelector(".confirm-model-homepage")

        btn_confirm_homepage.addEventListener("click", () => {
            modal_confirm_homepage.style.display = "block";
        });

        submit_btn_confirm_homepage.addEventListener("click", () => {
            hideModal();
        });

        span_confirm_homepage.forEach(item => {
            item.addEventListener('click', function () {
                hideModal();
            })
        })

        function hideModal() {
            modal_confirm_homepage.style.display = "none";
        }

        window.onclick = function (event) {
            if (event.target == modal_confirm_homepage) {
                hideModal();
            }
        };
    }


    $('#accordion #help_que').hide();
    $('#accordion .questions_container:first').addClass('active').next().show();
    $('#accordion .questions_container').click(function (e) {
        e.preventDefault();
        if ($(this).next().is(':hidden')) {
            $('#accordion .questions_container').removeClass('active').next().slideUp('slow');
            $(this).toggleClass('active').next().slideDown('slow');
        }
    });

    $(' .visit_msg a').on('click', function () {
        var text = $('.showMsg_label').text();
        $('.showMsg_label').text(
            text == "عرض الرسائل" ? "إخفاء الرسائل" : "عرض الرسائل");


        $('.visit_details_header').toggleClass('removeBorder');
        $('.visit-details_page .msg_history_body').toggleClass('active');
        $('html,body').animate({
            scrollTop: $(".msg_history_body").offset().top
        },
            'slow');

    })
    $(' .closeChat').on('click', function () {


        $('.visit-details_page .msg_history_body').removeClass('active');


    })



    initDatePicker();
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var headerOffset = $('.header-container').offset().top;
        if (scroll > headerOffset) {
            $(".header-fixed").addClass("fixed");
        } else {
            $(".header-fixed").removeClass("fixed");
        }
    });
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 200) {
            $('#homePage.notLogged .mobile_container').animate({
                'opacity': '1'
            }, 1000);
        }

        if (scroll >= 50) {
            $('#homePage.loggedIn .mobile_container').animate({
                'opacity': '1'
            }, 2000).delay(1000);
        }
    });

    //handling the subscribe button in thank you page

    $('.sg-submit-btn').on('click', function () {
        if ($('html').attr('lang') == 'ar') {
            $('.sg-responsee').html('لقد اشتركت بنجاح في خدمة ايميلات اطلب طبيب سوف توفر لك هذه القائمة البريدية المعلومات المتعلقة بخدماتنا والتحديثات الجديدة مننا')
        } else {
            $('.sg-responsee').html('You have successfully subscribed to Otlob Tabib. This mailing list provides you with information related to our services and updates from us.')
        }
    })

    //handling model of the doctor details page

    $('.model-details-toggle').on('click', function (e) {
        e.preventDefault();
        $('.model-details').toggleClass('is-visible');
    });

    //handling model of the blog listing page

    $('.model-blog-listing-toggle').on('click', function (e) {
        e.preventDefault();
        $('.model-blog-listing').toggleClass('is-visible');
    });

    //handling model of the blog listing page subscribe btn

    $('.model-blog-subscribe-toggle').on('click', function (e) {
        e.preventDefault();
        $('.model-blog-subscribe').toggleClass('is-visible');
        $('html, body').animate({
            scrollTop: $(".model-blog-subscribe").offset().top
        });
    });

    //handle search input in blogs page on responsive

    $('.blogs-section-search-input').on('click', function (e) {
        $('#search-blogs-inputField').addClass('handleBlogFormInput')
    })

    //handle file name to show beside the file in regForm homepage
    $('.input-upload-contactus').change(function () {
        $('#fileuploadPath-homePageForm').html(this.files[0].name)
    });

    //handle star rate on client/reservation page model
    function ratingHandler() {
        const stars = document.querySelectorAll('.doctor-details-model-rating i')
        for (let x = 0; x < stars.length; x++) {
            stars[x].starValue = (x + 1);

            ["mouseover", "mouseout", "click"].forEach(function (e) {
                stars[x].addEventListener(e, starRate)
            })
        }

        function starRate(e) {
            let t = e.type;
            let starValue = this.starValue
            stars.forEach(function (ele, ind) {
                if (t === "click") {
                    if (ind < starValue) {
                        ele.classList.add('active')
                        document.querySelector('[name="given_rating"]').value = starValue
                    } else {
                        ele.classList.remove('active')
                    }
                }
                if (t === "mouseover") {
                    if (ind < starValue) {
                        ele.classList.add('active-hover')
                    } else {
                        ele.classList.remove('active-hover')
                    }
                }

                if (t === "mouseout") {
                    ele.classList.remove('active-hover')
                }

            })
        }
    }
    ratingHandler()

    //handle tabs onjoinus page
    $('.tab').on('click', function (evt) {
        evt.preventDefault();
        $('.tab').removeClass('active');
        $(this).addClass('active');
        var sel = this.getAttribute('data-toggle-target');
        $('.tab-content').removeClass('active').filter(sel).addClass('active');
    });


    if ($('.counter-js').length > 0) {
        $('.counter-js').counterUp({
            delay: 20,
            time: 2000
        });
    }
    // notification //
    var alertMessage = $(".alert").data('message')
    var alertType = $(".alert").data('type')
    var n = $('.alert').noty({
        text: alertMessage,
        type: alertType,
        theme: 'metroui',
        layout: 'topRight',
        timeout: 6000,
        progressBar: true,
        animation: {
            open: {
                height: 'toggle'
            }, // or Animate.css class names like: 'animated bounceInLeft'
            close: {
                height: 'toggle'
            }, // or Animate.css class names like: 'animated bounceOutLeft'
            easing: 'swing',
            speed: 500 // opening & closing animation speed
        },
        closeWith: ['click'], // ['click', 'button', 'hover', 'backdrop'] // backdrop click will close all notifications
        modal: false, // [boolean] if true adds an overlay
        killer: false, // [boolean] if true closes all notifications and shows itself
        callback: {
            onShow: function () { },
            afterShow: function () { },
            onClose: function () { },
            afterClose: function () { },
            onCloseClick: function () { },
        },
        buttons: false // [boolean|array] an array of buttons, for creating confirmation dialogs.
    });
    $("footer .important_links  li:nth-child(2)").addClass('link_with_border');
    $('.blogListing .articles_section_container .articles_box ').addClass("screen:col:4 display:col:4 medium:col:6 small:col:12 float:right");
    //
    $(".profile_page .row:last-child").addClass('noBorder');
    $('.how_it_work_user , .user_tab').addClass('active');
    $('.how_it_works_page .how_it_work_user , .how_it_works_page .user_tab').removeClass('active');
    $('.how_it_works_page .how_it_work_doctor , .how_it_works_page .doctor_tab').addClass('active');
    $('#userType').val('doctor');
    $('.doctor_form_tab').addClass('active');
    $('.form_tab_header').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    })
    $('.user_form_tab').on('click', function () {
        $('.register_form_container form').addClass('IsUser');
        $('.terms_btn a , .terms-form-link').attr('href', general.base_url + '/شروط-الاستخدام');
        $('#userType').val('client');
    })
    $('.doctor_form_tab').on('click', function () {
        $('.register_form_container form').removeClass('IsUser');
        $('.terms_btn a , .terms-form-link').attr('href', general.base_url + '/شروط-الاستخدام-للطبيب');
        $('#userType').val('doctor');
    })
    $('.doctor_form_tab').addClass('active');
    $('.form_tab_header').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    })

    $('.close-noti').on('click', function () {
        $(this).parent().hide();
    })
    // profile links
    $('.profil_links a').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    })
    // Edit profile according
    $('.accordion #accordion_container h3').click(function (e) {
        $(e.target).next('.accordion #accordion_container div').siblings('.accordion #accordion_container div').slideUp('fast');
        $(e.target).next('.accordion #accordion_container div').slideToggle('fast');
    });
    $(".profile_page .row:last-child").addClass('noBorder');
    $('.doctor_tab').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
        $('.how_it_work_user').removeClass('active');
        $('.how_it_work_doctor').addClass('active');
    })
    $('.user_tab').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
        $('.how_it_work_doctor').removeClass('active');
        $('.how_it_work_user').addClass('active');
    })
    $('.toogle_navbar_icon').on('click', function () {
        $('header .navbar').addClass('openNav');
    })
    $('.link_back').on('click', function () {
        $('header .navbar').removeClass('openNav');
    })

    $('.notification .link_back').on('click', function () {
        $('#notification_list').toggleClass('openNotification');
    })
    // PHONE

    $("#phone, input[type=tel]").intlTelInput({
        numberType: 'MOBILE',
        initialCountry: "eg",
        defaultCountry: "eg",
        utilsScript: AssetPath+"/assets/js/utils.js",
        nationalMode: false,
        geoIpLookup: function (callback) {
            var date = new Date().getTime();
            $.get(BASEURL + '/getipinfo?time=' + date, function () { }).always(function (resp) {
                var countryCode = (resp && resp.country) ? resp.country : "gb";
                callback(countryCode)
            });
        }
    });
    $('.doctor_form_tab').addClass('active');
    $('.form_tab_header').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    })
    $('.user_form_tab').on('click', function () {
        $('.register_form_container form').addClass('IsUser');
    })
    $('.doctor_form_tab').on('click', function () {
        $('.register_form_container form').removeClass('IsUser');
    })
    $('.doctor_form_tab').addClass('active');
    $('.form_tab_header').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    })


    // clinicDetails
    $('.checkbox-reservation-appointment-checkbox').on('change', function () {
        $('.checkbox-reservation-appointment-checkbox').prop('checked', false)
        $(this).prop('checked', true)
        let clinicId = $(this).data('clinicid')
        let dayId = $(this).data('dayid')
        let reservationDate = $(this).data('reservationdate')
        let clinicTimeId = $(this).data('clinictimeid')
        let transformReservationDate = reservationDate.replace('/', '-')
        $('#handle-choosetime-error').removeClass('hide')

        $('#hidden-dayId').val(dayId)
        $('#hidden-dateReservation').val(transformReservationDate)
        $('#hidden-clinicTimeId').val(clinicTimeId)
        $.ajax({
            url: `/get-reservation-times-by-date/${clinicId}/${transformReservationDate}`, success: function (result) {
                $('.choose-time').empty()
                result.map(res => {
                    $('.choose-time').append(`<li class="list-inline-item" data-clinictimeid="${res.clinic_time_id}">${res.from} / ${res.to}</li>`)
                })
            }
        });
    })

    $('body').delegate('.choose-time li', 'click', function () {
        $('.choose-time li').removeClass('active')
        $(this).addClass('active')
        $('#hidden-timeReservation').val($(this).html())
        $('#hidden-clinicTimeId').val($(this).data('clinictimeid'))
        $('.btn-clinicDetails-appointment').prop('disabled', false);
    })



    /* owl curosel */


    // FB.api(
    //     '/937895752980438/feed?access_token=EAACEdEose0cBAPG9ZCORU54IZCcECbjSJoFuRDMDolJiqQrLJwrwNPqKStTNjmqOBgp93Gxz9WyeY6jX1Kr3hNWxeM6p6j5ExicSWZB7WIZBoYpOBS3qLxFv3WA1NwI4f4zt5gDfGDQgcLZAcUGel9eI6MRmxp5E5UE0ZCQWyuKjbZBLuVqmqVFKYrZBtbZCGbHVVLoamtoaFpQZDZD',
    //     'GET',
    //     { "fields": "attachments , message ,permalink_url " },

    //     function (response) {



    //         console.log(response)
    //         for (var index = 0; index < 3; index++) {
    //             var d = response.data[index].attachments.data[0].media;
    //             if (typeof d !== 'undefined') {
    //                 $('.footer-post .articles_box_container').append('<div class="articles_box">\
    //           <a href="'+ response.data[index].permalink_url + '" class="inner_container">\
    //             <div class="artcle_img">\
    //              <div class="article_img_overlay"><div class="overlay_readMore"><span>'+ general.labels.readmore + ' <i class="fa fa-search-plus" aria-hidden="true"></i></span></div></div>\
    //              <img src="'+ response.data[index].attachments.data[0].media.image.src + '">\
    //              </div>\
    //              <div class="article_details">\
    //                <h3 href="" class="artcle_title">'+ response.data[index].message + '</h3>\
    //                <p></p>\
    //              </div>\
    //           </a>\
    //      </div>'
    //                 )
    //             }
    //         }




    //     }

    // );

    $(' #homePage .testimonial_section_container .owl-carousel').owlCarousel({
        loop: true,

        autoplay: true,
        autoPlaySpeed: 4000,
        autoPlayTimeout: 4000,
        autoplayHoverPause: true,
        responsiveClass: true,
        margin: 30,
        rtl: true,
        responsive: {
            0: {
                items: 1,
                nav: true,
                maring: 0
            },
            480: {
                items: 1,
                nav: true,
                maring: 0
            },
            600: {
                items: 2,
                nav: true,
                maring: 20
            },
            1000: {
                items: 3,
                nav: true,
                maring: 20
            }
        },
        nav: true,
        navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>']
    });
    $('.owl-gallery').owlCarousel('refresh');



    if ($('#svgCONTAIN').length > 0) {
        $('#svgCONTAIN ').load(SVGPATH, function () {
            $('#svgCONTAIN_doctor ').load(SVGPATH_doctor, function () {
                // toogle navbar
                /* HOW IT WORKS USERS - NEXT ARROW  START HERE */
                var next_step_counter = 1;
                $('.how_it_work_user .next_step').on('click', function () {
                    console.log(next_step_counter)
                    if (next_step_counter < 4) {
                        next_step_counter = next_step_counter + 1;
                        $('#svgCONTAIN').addClass('step_' + next_step_counter);
                        eval("var current_step_title = step_user_title_" + next_step_counter);
                        eval("var current_step_desc = step_user_desc_" + next_step_counter);
                        $(".how_it_work_user #step_bullet_" + next_step_counter).addClass('active').siblings().removeClass('active');
                        document.getElementById("step_title_content").textContent = current_step_title;
                        document.getElementById("step_title_desc").textContent = current_step_desc;
                    }
                });
                $('.how_it_work_user .prev_step').on('click', function () {
                    if (next_step_counter > 0) {
                        $('#svgCONTAIN').removeClass('step_' + next_step_counter);
                        next_step_counter = (next_step_counter - 1);
                        eval("var current_step_title = step_user_title_" + next_step_counter);
                        eval("var current_step_desc = step_user_desc_" + next_step_counter);
                        $(".how_it_work_user #step_bullet_" + next_step_counter).addClass('active').siblings().removeClass('active');
                        document.getElementById("step_title_content").textContent = current_step_title;
                        document.getElementById("step_title_desc").textContent = current_step_desc;
                    }
                });
                $('.how_it_work_user .bullet_item').on('click', function () {
                    $(this).addClass('active').siblings().removeClass('active');
                    var clicked_bullet = $(this).attr("id").replace(/step_bullet_/, '');
                    $('#svgCONTAIN').attr("class", 'step_' + clicked_bullet);
                    eval("var current_step_title = step_user_title_" + clicked_bullet);
                    eval("var current_step_desc = step_user_desc_" + clicked_bullet);
                    document.getElementById("step_title_content").textContent = current_step_title;
                    document.getElementById("step_title_desc").textContent = current_step_desc;
                    next_step_counter = parseInt(clicked_bullet);
                });
                /* HOW IT WORKS USERS - NEXT ARROW  END HERE */
                /* HOW IT WORKS DOCTOR - NEXT ARROW  START HERE */
                var next_step_counter_doctor = 1;
                $('.how_it_work_doctor .next_step').on('click', function () {
                    $('.step_title').addClass('active');
                    if (next_step_counter_doctor < 6) {
                        next_step_counter_doctor = next_step_counter_doctor + 1;
                        $('#svgCONTAIN_doctor').addClass('step_' + next_step_counter_doctor);
                        eval("var current_step_title_doctor = step_doctor_title_" + next_step_counter_doctor);
                        eval("var current_step_desc_doctor = step_doctor_desc_" + next_step_counter_doctor);
                        $(".how_it_work_doctor #step_bullet_" + next_step_counter_doctor).addClass('active').siblings().removeClass('active');
                        document.getElementById("step_title_content_doctor").textContent = current_step_title_doctor;
                        document.getElementById("step_title_desc_doctor").textContent = current_step_desc_doctor;
                    }
                });
                $('.how_it_work_doctor .prev_step').on('click', function () {
                    if (next_step_counter_doctor > 0) {
                        $('#svgCONTAIN_doctor').removeClass('step_' + next_step_counter_doctor);
                        next_step_counter_doctor = (next_step_counter_doctor - 1);
                        eval("var current_step_title_doctor = step_doctor_title_" + next_step_counter_doctor);
                        eval("var current_step_desc_doctor = step_doctor_desc_" + next_step_counter_doctor);
                        $(".how_it_work_doctor #step_bullet_" + next_step_counter_doctor).addClass('active').siblings().removeClass('active');
                        document.getElementById("step_title_content_doctor").textContent = current_step_title_doctor;
                        document.getElementById("step_title_desc_doctor").textContent = current_step_desc_doctor;
                    }
                });
                $('.how_it_work_doctor .bullet_item').on('click', function () {
                    $(this).addClass('active').siblings().removeClass('active');
                    var clicked_bullet_doctor = $(this).attr("id").replace(/step_bullet_/, '');
                    $('#svgCONTAIN_doctor').attr("class", 'step_' + clicked_bullet_doctor);
                    eval("var current_step_title_doctor = step_doctor_title_" + clicked_bullet_doctor);
                    eval("var current_step_desc_doctor = step_doctor_desc_" + clicked_bullet_doctor);
                    document.getElementById("step_title_content_doctor").textContent = current_step_title_doctor;
                    document.getElementById("step_title_desc_doctor").textContent = current_step_desc_doctor;
                    next_step_counter_doctor = parseInt(clicked_bullet_doctor);
                });
                /* HOW IT WORKS DOCTOR - NEXT ARROW  END HERE */
            });
        });
    }
});

function hideMessage() {
    $('.profile_notifiacation').hide();
}

function showMessage(message, type) {
    $('.profile_notifiacation.' + type).show().find('h3').text(message);
}

$('.notification_item[rel-section]').on('click', function () {
    var relatedSection = $(this).attr('rel-section')
    window.location.href = '/profile/doctor/edit#' + relatedSection
})



// dropdown menu for mobile view

$(function () {
    $('ul.nav_inner_container .dropdown.main_nav_links .parent-link').map(function () {
        $(this).siblings('.dropdown-content').css('display', 'none');
        $(this).on('click', function (event) {
            //console.log('clicked');
            // console.log($(this).children('.child-nav-item').css('display'));

            $(this).parent('.dropdown.main_nav_links').siblings('.dropdown.main_nav_links').map(function () {
                if ($(this).children('.dropdown-content').css('display') === 'block') {
                    // $(this).removeClass('active')
                    $(this).children('.dropdown-content').animate({
                        // height: "100%"
                        height: "toggle"
                    }, 500)
                }
            })

            $(this).siblings('.dropdown-content').animate({
                height: "toggle"
            })
        })
    })
})





/* ----------------------------------------------------------------------------------------- */
/* ------ populate areas in the home page  */

window.onload = function () {
    let citiesWithAreas = {}
    $('#city_id_ option.option').map(function () {
        // console.log("areas>>>>>", $(this).data('areas'));
        // console.log($(this).val());
        citiesWithAreas[$(this).val()] = $(this).data('areas').map((item) => [item.id, item.name])
    })
    // console.log($('#city_label_'));
    // console.log("the object", citiesWithAreas);

    let citySelectedValue = $('#city_id_').val()
    let areaSelectedValue = $('#area_id_').val()

    if (citySelectedValue) {

        if (citiesWithAreas.hasOwnProperty(citySelectedValue)) {
            // console.log("the key", citiesWithAreas[e.target.value]);
            citiesWithAreas[citySelectedValue].map((item) => {
                console.log("the item:", item);
                document.querySelector('#area_id_').innerHTML += `
                <option class="option" value='${item[0]}'>${item[1]}</option>
                `
            })
        }
    }

    $('#area_id_').val(areaSelectedValue)










    if (document.getElementById('city_id_') !== null && typeof document.getElementById('city_id_') !== 'undefined') {
        document.querySelector('#city_id_').addEventListener('change', function (e) {
            // console.log("the option value", e.target.value);
            // $('#city_label_').removeAttr('selected')
            Array.from(document.querySelectorAll('#area_id_ option.option')).map((item) => {
                item.remove()
            })
            // for (const key in citiesWithAreas) {
            if (citiesWithAreas.hasOwnProperty(e.target.value)) {
                // console.log("the key", citiesWithAreas[e.target.value]);
                citiesWithAreas[e.target.value].map((item) => {
                    // console.log("the item:", item);
                    document.querySelector('#area_id_').innerHTML += `
                        <option class="option" value='${item[0]}'>${item[1]}</option>
                        `
                })


            }
            // }
        })
    }


    // handle sticky download app banner
    var isiPhone = /iphone/i.test(navigator.userAgent.toLowerCase());
    if (isiPhone) {
        $('.ios-mobile').removeClass('hide')
    }

    var isAndroid = /android/i.test(navigator.userAgent.toLowerCase());

    if (isAndroid) {
        $('.android-mobile').removeClass('hide')
    }

    $('.close-icon-downloadapp').on('click', function () {
        $('.download-app-sticky').addClass('hide')
    })



}

$(document).ready(function () {
    var swiper = new Swiper(".mySwiper", {
        loop: false,
        spaceBetween: 15,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 2,
            },
            // when window width is >= 480px
            425: {
                slidesPerView: 3,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 4,
            }
        }
    });
    var swiper2 = new Swiper(".mySwiper2", {
        loop: true,
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });
});

/* ----------------------------------------------------------------------------------------- */
