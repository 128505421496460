function popImage(file, image, target) {
    var reader = new FileReader();
    reader.onload = function(e) {
        var imageSource = e.target['result']
        $(target).find('img').attr('src', imageSource);
    }
    reader.readAsDataURL(file);
}




function callForm(formid, callback) {
    var form
    if (formid) {
        form = document.querySelector('#' + formid);
    } else {
        form = document.forms[0];
    }
    var method = form.attributes.method.value
    var url = form.attributes.action.value
    var data = new FormData();
    data.append("_method", method);
    for (var i = 0; i < form['elements'].length; i++) {
        var item = form['elements'][i];
        if (typeof item.attributes.name !== 'undefined') {
            if (item.type == 'checkbox') {
                data.append(item.attributes.name.value, item.checked);
            } else if (item.nodeName === 'SELECT') {
                data.append(item.name, item.value);
            } else {
                if (item.attributes.type && item.attributes.type.value === 'file') {
                    var files;
                    var file_name = '';
                    if (item.files.length > 1) {
                        files = item.files;
                    } else if (item.files.length == 1) {
                        files = item.files[0];
                        var id = item.attributes.id ? item.attributes.id.value + '_' : '';
                        file_name = id + item.files[0].name
                    } else {
                        files = [0]
                    }
                    if (files) {
                        data.append(item.attributes.name.value, files);
                    }
                } else {
                    data.append(item.attributes.name.value, item.value);
                }
            }
        }
    }
    var xhr = new XMLHttpRequest();
    $('.loader').addClass('on')
    $('body').find('input[type="submit"], button').attr('disabled', 'disabled')
    xhr.upload.addEventListener("progress", function(evt) {
        if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            percentComplete = parseInt(percentComplete * 100);
            $('.loader').find('.bar').css('width', percentComplete + '%')
        }
    }, false);

    xhr.open("POST", url);
    xhr.send(data);
    return xhr.addEventListener("readystatechange", function() {
        $('.loader').removeClass('on').find('.bar').css('width', 0)
        $('body').find('input[type="submit"], button').removeAttr('disabled')
        if (this.readyState == 4) {
            callback(this);
            var hihi = JSON.parse(this.responseText);
            var msg = hihi.status.message;
            console.log(msg);
            if (hihi.status.status !== 0) {
                var n = noty({

                    layout: 'topCenter',
                    timeout: 3000, // [integer|boolean] delay for closing event in milliseconds. Set false for sticky notifications
                    progressBar: true, // [boolean] - displays a progress bar
                    text: msg,
                    type: 'success',
                    animation: {
                        open: {
                            height: 'toggle'
                        },
                        close: {
                            height: 'toggle'
                        },
                        easing: 'swing',
                        speed: 500 // opening & closing animation speed
                    },
                    modal: false, // [boolean] if true adds an overlay
                    killer: true // [boolean] if true closes all notifications and shows itself
                });

                $('#qualification_form ').removeClass('show');
                $('#specialized_form ').removeClass('show');
                $('#experiences_form').removeClass('show');
            }
            return this;
        } else {
            return false
        }
    });
}

// file populate
$('body').on('change', 'input[type=file][populate]', function() {
    var file = this.files[0];
    var image = $(this).attr('imageSource');
    popImage(file, image, $(this).parent())
    if ($(this).is('[name*=experience_file]')) {
        var cloned = $(this).parent().clone();
        cloned.find('input').val('')
        cloned.find('img').attr('src', '/assets/images/placeholder_add.png')
        $(this).parent().after(cloned)
    }
})

// UPLOAD PROFILE IMAGE // 
$('.choose').on('change', function() {
    callForm("edit_profile_form", function(data) {
        if (data) {
            var json_personal_img = JSON.parse(data.responseText);
            $('.profileLoader img').attr('src', json_personal_img.data.image_full_path);
            initDatePicker()
        } else {}
    })
});
// ADD qulaification //

$('#qualificationForm').validate({
    rules: {
        field: {
            required: true
        },
        agree: {
            required: true
        }
    },
    messages: {
        name: " الرجاء إدخال إسم التخصص",
        type: "الرجاء إدخال نوع التخصص",
        issued_date: "الرجاء ادخال عام التخرج",
        institute: "الرجاء ادخال المعهد/الجامعه",
        occupation: "الرجاء ادخال occupation",
        ref_number: "الرجاء ادخال refrenence number",
        attachment: "الرجاء إرفاق صورة الشهادة"
    },
    submitHandler: function(form) {
        callForm("qualificationForm", function(data) {
            if (data) {
                var json_qulaification_img = JSON.parse(data.responseText);
                $('.qualificationAttachment').attr('src', json_qulaification_img.text);

                $.get(window.location.href).done(function(response) {
                    var newSection = $(response).find('.certificates_details');
                    $(newSection).addClass('showDetails');
                    $('.certificates_details').replaceWith(newSection);
                    if (json_qulaification_img.status.status == 1) {
                        $('.noDegree_notify-certification').delay(1000).removeClass('show-noty')
                        $('#qualification_form').removeClass('show');
                          noty({
        type:'info',                      
        layout: 'topCenter',
        text: 'هل تريد إضافة شهادة مكتسبة اخرى ؟',
        buttons: [{
            addClass: 'btn btn-primary',
            text: 'أضف شهادة اخرى',
             onClick: function($noty) {
            $('body form , body div ').removeClass('show');
            $('#qualification_form form input , #qualification_form form textarea').val('');
            $('#qualification_form').find('img').attr('src', '/assets/images/placeholder_add.png')
            $('#qualification_form ').addClass('show');
                $noty.close();

            }
        }, {
            addClass: 'btn btn-danger',
             text:'لا, شكراً',
            onClick: function($noty) {
                
                $noty.close();
            }
        }]
    });
                    }
                    initDatePicker()
                })
            }
        })
    }

});




//edit qualification //
$('body').on('click', '.editQualificationButton', function() {
    $('body form , body div ').removeClass('show');
    var editqualificationFormId = $(this).data("qualificationid");



    $('#qualificationEdit_form_' + editqualificationFormId).validate({
        rules: {
            field: {
                required: true
            },
            agree: {
                required: true
            }
        },
        messages: {
            name: " الرجاء إدخال إسم التخصص",
            type: "الرجاء إدخال نوع التخصص",
            issued_date: "الرجاء ادخال عام التخرج",
            institute: "الرجاء ادخال المعهد/الجامعه",
            occupation: "الرجاء ادخال occupation",
            ref_number: "الرجاء ادخال refrenence number",
            attachment: "الرجاء إرفاق صورة الشهادة"
        }
    });
    $('#qualificationEdit_form_' + editqualificationFormId).on('submit', function(e) {
        callForm("qualificationEdit_form_" + editqualificationFormId, function(data) {
            if (data) {
                var json_qulaification_img_update = JSON.parse(data.responseText);
                $('.qualificationAttachment').attr('src', json_qulaification_img_update.text);
                $.get(window.location.href).done(function(response) {
                    var newSection = $(response).find('.certificates_details');
                    $(newSection).addClass('showDetails');

                    if (json_qulaification_img_update.status.status == 1) {
                        $('.qualificationEditDiv').removeClass('show');
                        $('.certificates_details').replaceWith(newSection);
                    }
                    initDatePicker()
                })
            }
        })
        e.preventDefault();
    });
});
// ADD experince //


$('#experienceForm').validate({
    rules: {
        field: {
            required: true
        },
        agree: {
            required: true
        }
    },
    messages: {
        experience_title: " الرجاء إدخال  المسمى الوظيفى",
        experience_start_date: "ا`لرجاء اختيار تاريخ التعيين",
        experience_until_date: "الرجاء اختيار تاريخ التعيين حتى",
        experience_place: "الرجاء ادخال مكان العمل ",
        experience_description: "الرجاء ادخال مكان وصف العمل",
    },
    submitHandler: function(form) {
        callForm("experienceForm", function(data) {
            if (data) {
                var json_experience_img = JSON.parse(data.responseText);
                $('#experienceImg').attr('src', json_experience_img.text);
                $.get(window.location.href).done(function(response) {
                    var newSection = $(response).find('.experience_profile .experience_details');
                    $(newSection).addClass('showDetails');
                    $('.experience_profile .experience_details').replaceWith(newSection);

                    if (json_experience_img.status.status == 1) {
                        $('.noDegree_notify-experience').delay(1000).removeClass('show-noty')
                        $('#experiences_form').removeClass('show');
                        var images_length = json_experience_img.data.full_path.length
                        $('#experience_profile .qualificationImgContainer .grouped_elements:after').css('content', images_length);
                   
                        noty({
                            type:'info', 
                            layout: 'topCenter',
                            text: 'هل تريد إضافة شهادة خبرة اخرى ؟',
                            buttons: [{
                                addClass: 'btn btn-primary',
                                text: 'أضف شهادة اخرى',
                                onClick: function($noty) {
                                 $('body form , body div').removeClass('show');
                                $('#experiences_form form input, #experiences_form form textarea').val('');
                                $('#experiences_form').find('img').attr('src', '/assets/images/placeholder_add.png')
                                $('#experiences_form ').addClass('show');
                                    $noty.close();

                                }
                            }, {
                                addClass: 'btn btn-danger',
                                text:'لا, شكراً',
                                onClick: function($noty) {
                                    
                                    $noty.close();
                                }
                            }]
                        });        
            
         }
                    initDatePicker()
                })
            }
        })
    }

});



// Edit experince //
$('body').on('click', '.editExperienceButton', function() {


    var editexperienceFormId = $(this).attr("data-experienceid");


    $('#ExperienceEdit_form_' + editexperienceFormId).validate({
        rules: {
            field: {
                required: true
            },
            agree: {
                required: true
            }
        },
        messages: {
            experience_title: " الرجاء إدخال  المسمى الوظيفى",
            experience_start_date: "ا`لرجاء اختيار تاريخ التعيين",
            experience_until_date: "الرجاء اختيار تاريخ التعيين حتى",
            experience_place: "الرجاء ادخال مكان العمل ",
            experience_description: "الرجاء ادخال مكان وصف العمل",


        }
    })

    $('#ExperienceEdit_form_' + editexperienceFormId).on('submit', function(e) {
        callForm("ExperienceEdit_form_" + editexperienceFormId, function(data) {

            if (data) {
                var json_experience_img_x = JSON.parse(data.responseText);
                $.get(window.location.href).done(function(response) {
                    var newSection = $(response).find('.experience_profile .experience_details');
                    $(newSection).addClass('showDetails');
                    if (json_experience_img_x.status.status == 1) {
                        $('.experienceEditDiv').removeClass('show');
                        $('#experience_profile .experience_details').replaceWith(newSection);
                         
                    }
                    initDatePicker()
                })
            }
        })
        e.preventDefault();
    });
});

// ADD specializing //

$('#specializedForm').validate({
    rules: {
        field: {
            required: true
        },
        agree: {
            required: true
        }
    },
    messages: {
        specialityName: " الرجاء إدخال  اسم التخصص"
    },
    submitHandler: function(form) {
        callForm("specializedForm", function(data) {
            if (data) {
                var json_experience_img_x = JSON.parse(data.responseText);
                $.get(window.location.href)
                    .done(function(response) {
                        var newSection = $(response).find('#specializedIn_profile .specialized_details');
                        if (json_experience_img_x.status.status == 1) {
                            $(newSection).addClass('showDetails');
                            $('#specializedIn_profile .specialized_details').replaceWith(newSection);
                            $('.noDegree_notify-specialization').delay(1000).removeClass('show-noty')
                            $('#specialized_form').removeClass('show');

                             noty({
                                 type:'info', 
                            layout: 'topCenter',
                            text: 'هل تريد إضافة تخصص اخر ؟ ',
                            buttons: [{
                                addClass: 'btn btn-primary',
                                text: 'أضف تخصص أخر',
                                onClick: function($noty) {
                               $('body form , body div').removeClass('show');
                                $('#specializedForm input, #specializedForm textarea').val('');
                                $('#showAddSpecialized').find('img').attr('src', '/assets/images/placeholder_add.png')
                                $('#specialized_form').addClass('show');
                                    $noty.close();

                                }
                            }, {
                                addClass: 'btn btn-danger',
                                text:'لا, شكراً',
                                onClick: function($noty) {
                                    
                                    $noty.close();
                                }
                            }]
                        });  
                        }
                        initDatePicker()
                    })
            }
        })
    }

});





// Edit specializing //
$('body').on('click', '.editSpecialized', function() {
    var editspechialFormId = $(this).attr("data-specializedid");
    $('#SpechialEdit_form_' + editspechialFormId).validate({
        rules: {
            field: {
                required: true
            },
            agree: {
                required: true
            }
        },
        messages: {
            specialityName: " الرجاء إدخال  اسم التخصص"


        }
    });
    $('#SpechialEdit_form_' + editspechialFormId).on('submit', function(e) {
        callForm("SpechialEdit_form_" + editspechialFormId, function(data) {
            if (data) {
                var json_experience_img_x = JSON.parse(data.responseText);
                $.get(window.location.href)
                    .done(function(response) {
                        var newSection = $(response).find('#specializedIn_profile .experience_details');
                        $(newSection).addClass('showDetails');
                        if (json_experience_img_x.status.status == 1) {
                            $('#specialityEditDiv').removeClass('show');
                            $('#specializedIn_profile .experience_details').replaceWith(newSection);
                        }
                        initDatePicker()
                    })
            }
        })
        e.preventDefault();
    });
});

getCity();

function getCity() {
    var conId = $("#country").val();
    if (conId !== '') {
        $.get("/cities/" + conId, function(data) {
            if (data.status.status == 0) {
                NoData('city')
            } else {
                Select('city');
                $.each(data.data, function(key, value) {
                    $('#city').append($("<option></option>").attr("value", data.data[key].id).text(data.data[key].name));
                    var city_id = $("#current_city_id").val(); //Get var from hidden input
                    if (city_id == data.data[key].id) {
                        $('#city option[value="' + parseInt(city_id) + '"]').attr('selected', 'selected');
                    }
                });
            }
            getArea()
        });
    }
}

function getArea() {
    var cid = $("#city").val();
    if (cid !== '') {
        $.get("/areas/" + cid, function(data) {
            if (data.status.status == 0) {
                NoData('area')
            } else {
                Select('area');
                $.each(data.data, function(key, value) {
                    $('#area').append($("<option></option>").attr("value", data.data[key].id).text(data.data[key].name));
                    var area_id = $("#current_area_id").val();
                    if (area_id == data.data[key].id) {
                        $('#area  option[value="' + parseInt(area_id) + '"]').attr('selected', 'selected');
                    }
                });
            }
        });
    }
}
$('#country').change(function() {
    getCity()
});
$('#city').change(function() {
    getArea()
});
/* Select Option */
function Select(name) {
    var selector = '#' + name + '';
    $(selector).empty();
    $(selector).append($("<option></option>").attr("value", 0).text('Select ...'));
    $(selector).prop("disabled", false);
}
/* No data option */
function NoData(name) {
    var selector = '#' + name + '';
    $(selector).empty();
    $(selector).append($("<option></option>").attr("value", 0).text('-- No data --'));
    $(selector).prop("disabled", true);
}
/* Delete Qualification */
$('body').on('click', '.deleteQualificationButton', function() {
    var $id = $(this).attr('data-qualificationId');
    var title = $(this).parents('.details_inner_container').find('.qualificationName').text();
    noty({
        layout: 'topCenter',
        text: 'هل تريد ان تحذف: ' + title,
        buttons: [{
            addClass: 'btn btn-primary',
            text: 'حذف',
            onClick: function($noty) {
                $.get('/profile/doctor/qualification/delete/' + $id, function(data) {
                    if (data.status.status == 1) {
                        $('#QualificationRow_' + $id).remove();
                        if ($('.certificates_details .details').length > 0) {

                            $('.noDegree_notify-certification').delay(1000).removeClass('show-noty')
                        } else {
                            $('.noDegree_notify-certification').delay(1000).addClass('show-noty')
                        }

                        noty({
                            layout: 'topCenter',
                            text: 'تم الحذف',
                            type: 'success',
                            timeout: 3000, // [integer|boolean] delay for closing event in milliseconds. Set false for sticky notifications
                            progressBar: true, // [boolean] - displays a progress bar
                            animation: {
                                open: {
                                    height: 'toggle'
                                },
                                close: {
                                    height: 'toggle'
                                },
                                easing: 'swing',
                                speed: 500 // opening & closing animation speed
                            },
                            modal: false, // [boolean] if true adds an overlay
                            killer: true // [boolean] if true closes all notifications and shows itself
                        });
                    }
                })
                $noty.close();

            }
        }, {
            addClass: 'btn btn-danger',
            text: 'ألغاء',
            onClick: function($noty) {
                $noty.close();
            }
        }]
    });
});
// Delete Experience
$('body').on('click', '.deleteExperienceButton', function() {
    var $id = $(this).attr('data-experienceId');
    var title = $(this).parents('.details_inner_container').find('.experience_title').text();
    noty({
        layout: 'topCenter',
        text: 'هل تريد ان تحذف: ' + title,
        buttons: [{
            addClass: 'btn btn-primary',
            text: 'حذف',
            onClick: function($noty) {
                $.get('/profile/doctor/experience/delete/' + $id, function(data) {
                    if (data.status.status == 1) {
                        $('#ExperienceRow' + $id).remove();
                        if ($('.experience_details .details').length > 0) {
                            $('.noDegree_notify-experience').delay(1000).removeClass('show-noty');
                        } else {
                            $('.noDegree_notify-experience').delay(1000).addClass('show-noty');
                        }
                        noty({
                            layout: 'topCenter',
                            text: 'تم الحذف',
                            type: 'success',
                            timeout: 3000, // [integer|boolean] delay for closing event in milliseconds. Set false for sticky notifications
                            progressBar: true, // [boolean] - displays a progress bar

                            animation: {
                                open: {
                                    height: 'toggle'
                                },
                                close: {
                                    height: 'toggle'
                                },
                                easing: 'swing',
                                speed: 500 // opening & closing animation speed
                            },
                            modal: false, // [boolean] if true adds an overlay
                            killer: true // [boolean] if true closes all notifications and shows itself
                        });
                    }
                })
                $noty.close();
            }
        }, {
            addClass: 'btn btn-danger',
            text: 'ألغاء',
            onClick: function($noty) {
                $noty.close();
            }
        }]
    });
});

// Delete specializing
$('body').on('click', '.deleteSpecialized', function() {
    var $id = $(this).attr('data-specializedId');
    var title = $(this).parents('.details_inner_container').find('.specialityName').text();
    noty({
        layout: 'topCenter',
        text: 'هل تريد ان تحذف: ' + title,
        buttons: [{
                addClass: 'btn btn-primary',
                text: 'حذف',
                onClick: function($noty) {
                    $.get('/profile/doctor/specializing/delete/' + $id, function(data) {
                        if (data.status.status == 1) {
                            $('#SpecializedRow' + $id).remove();
                            if ($('.specialityName').length > 0) {
                                $('.noDegree_notify-specialization').delay(1000).removeClass('show-noty')
                            } else {
                                $('.noDegree_notify-specialization').delay(1000).addClass('show-noty')
                            }
                            noty({ text: 'تم الحذف', type: 'success' });
                        }
                    })
                    $noty.close();
                }
            },
            {
                addClass: 'btn btn-danger',
                text: 'ألغاء',
                onClick: function($noty) {
                    $noty.close();
                }
            }
        ]
    });

});


// Delete specializing
$('body').on('click', '.removeAttachment', function(e) {
    var $id = $(this).attr('attachment-id');
    var that = this;
    noty({
        layout: 'topCenter',
        text: 'هل تريد ان تحذف هذا الملف',
        buttons: [{
                addClass: 'btn btn-primary',
                text: 'حذف',
                onClick: function($noty) {

                    $.ajax({
                        url: '/profile/doctor/experience/delete-experience-attachment/' + $id,
                        type: 'DELETE',
                        success: function(data) {
                            if (data.status.status == 1) {
                                $(that).parent().remove()

                                noty({ text: 'تم الحذف', type: 'success' });
                            }
                        }
                    })
                    $noty.close();
                }
            },
            {
                addClass: 'btn btn-danger',
                text: 'ألغاء',
                onClick: function($noty) {
                    $noty.close();
                }
            }
        ]
    });
    e.preventDefault();
    return false;
});

//add button //
$('body').on('click', '#showAddQualificationButton', function() {
    $('body form , body div ').removeClass('show');
    $('#qualification_form form input , #qualification_form form textarea').val('');
    $('#qualification_form').find('img').attr('src', '/assets/images/placeholder_add.png')
    $('#qualification_form ').addClass('show');
});
$('body').on('click', '#showAddExperienceButton', function() {
    $('body form , body div').removeClass('show');
    $('#experiences_form form input, #experiences_form form textarea').val('');
    $('#experiences_form').find('img').attr('src', '/assets/images/placeholder_add.png')
    $('#experiences_form ').addClass('show');
});
$('body').on('click', '#showAddSpecialized', function() {
    $('body form , body div').removeClass('show');
    $('#specializedForm input').val('');
    $('#showAddSpecialized').find('img').attr('src', '/assets/images/placeholder_add.png')
    $('#specialized_form').addClass('show');
});
//cancle button //
$('body').on('click', '#cancelQualificationAddForm', function() {
    $('#qualification_form ').removeClass('show');
});
$('body').on('click', '#cancelSpecializedAdd', function() {
    $('#specialized_form ').removeClass('show');
});
$('body').on('click', '#cancelExperienceAddForm', function() {
    $('#experiences_form').removeClass('show');
});

//edit button //
$('body').on('click', '.editQualificationButton', function() {
    $('body form , body div ').removeClass('show');
    var editqualificationFormId = $(this).attr("data-qualificationid");
    $('#QualificationRow_' + editqualificationFormId + ' .qualificationEditDiv').addClass('show');
});
$('body').on('click', '.cancelQualificationEditButton', function() {
    $('.qualificationEditDiv').removeClass('show');
});

$('body').on('click', '.editExperienceButton', function() {
    $('body form , body div ').removeClass('show');
    var editexperienceFormId = $(this).attr("data-experienceid");

    $('#ExperienceRow' + editexperienceFormId + ' .experienceEditDiv').addClass('show');
});
$('body').on('click', '.cancelExperienceEditButton', function() {
    $('.experienceEditDiv').removeClass('show');
});

$('body').on('click', '.editSpecialized', function() {
    //      $('body form , body div').removeClass('show');
    var editspechialFormId = $(this).attr("data-specializedid");
    $('#SpecializedRow' + editspechialFormId + ' .specialityEditDiv').addClass('show');
});
$('body').on('click', '.cancelSpecializedEdit', function() {
    $('.specialityEditDiv').removeClass('show');
});